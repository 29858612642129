var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card phone-width change-content"
  }, [_vm._m(0), _c('div', {
    staticClass: "wuwow-card-body text-center card-body"
  }, [_c('table', {
    staticClass: "table table-hover table-clsapc-record"
  }, [_c('thead', {
    staticClass: "thead-default"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('additional_purchase')))]), _c('th', [_vm._v(_vm._s(_vm.$t('additional_purchase_price')))]), _c('th'), _c('th', [_vm._v(_vm._s(_vm.$t('deduct_class')))]), _c('th')])]), _c('tbody', _vm._l(_vm.FAKE_change_content, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(item.description))]), _c('td', [_vm._v("NT $" + _vm._s(item.purchase_amount))]), _c('td', [_c('font-awesome-icon', {
      attrs: {
        "icon": "plus"
      }
    })], 1), _c('td', [_vm._v(_vm._s(item.cost_session))]), _c('td', [index === 0 ? _c('b-button', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.learn_rule_modal",
        modifiers: {
          "learn_rule_modal": true
        }
      }],
      staticClass: "btn-exchange",
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(_vm._s(_vm.$t('exchange')))]) : index === 1 ? _c('b-button', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.code_modal",
        modifiers: {
          "code_modal": true
        }
      }],
      attrs: {
        "variant": "outline-primary"
      }
    }, [_vm._v(_vm._s(_vm.$t('show_code')))]) : _c('span', [_vm._v("-")])], 1)]);
  }), 0)]), _c('div', [_c('span', {
    staticClass: "text-danger warning-text"
  }, [_vm._v(_vm._s(_vm.$t('additional_purchase_warning')))])]), _c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.openWushare();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('pruduct_link')))])], 1), _c('learn-rule-modal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card-head text-center"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v("WUWOW學員專屬堂數加價購(B)")])]);

}]

export { render, staticRenderFns }