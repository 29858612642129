var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "learn_rule_modal",
      "centered": "",
      "title": _vm.$t('learn_rule'),
      "header-bg-variant": "primary",
      "header-text-variant": "light",
      "hide-footer": ""
    }
  }, [_c('h5', {
    staticClass: "text-danger"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('notice_des', [5]))
    }
  })]), _c('br'), _c('h5', {
    staticStyle: {
      "font-weight": "700"
    }
  }, [_vm._v(_vm._s(_vm.$t('notice')))]), _c('p', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('learn_rule_rule_1'))
    }
  }), _c('br'), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('learn_rule_rule_2'))
    }
  })]), _c('b-form-checkbox', {
    attrs: {
      "id": "checkbox",
      "name": "checkbox",
      "value": "checked",
      "unchecked-value": "unchecked"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('have_read_rule'))
    }
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }