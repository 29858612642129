<template lang="pug">
  .wuwow-card.phone-width.change-content
    .wuwow-card-head.text-center
      h4.wuwow-card-title WUWOW學員專屬堂數加價購(B)
    .wuwow-card-body.text-center.card-body
      table.table.table-hover.table-clsapc-record
        thead.thead-default
          tr
            th {{$t('additional_purchase')}}
            th {{$t('additional_purchase_price')}}
            th
            th {{$t('deduct_class')}}
            th
        tbody
          tr(v-for="(item, index) in FAKE_change_content" :key="index")
            td {{ item.description }}
            td
              | NT ${{item.purchase_amount}}
            td
              font-awesome-icon(icon="plus")
            td
              | {{item.cost_session}}
            td
              b-button.btn-exchange(v-if=' index === 0 ' variant='primary' v-b-modal.learn_rule_modal) {{$t('exchange')}}
              b-button(v-else-if=' index === 1 ' variant='outline-primary' v-b-modal.code_modal) {{$t('show_code')}}
              span(v-else=' index === 2 ') -
      div
        span.text-danger.warning-text {{$t('additional_purchase_warning')}}
      b-button.mt-3(variant='primary' @click='openWushare()') {{$t('pruduct_link')}}
    learn-rule-modal
</template>

<script type="text/javascript">
import { deductClassPurchaseDiscountLang } from './lang';
import learnRuleModal from '@/components/wushare/learnRuleModal';
export default {
  name: 'DeductClassPurchaseDiscount',

  components: {
    learnRuleModal,
  },

  i18n: deductClassPurchaseDiscountLang,

  data() {
    return {
      FAKE_change_content: [
        { description: '方案A', purchase_amount: '2890', cost_session: '5' },
        { description: '方案B', purchase_amount: '1890', cost_session: '20' },
        { description: '方案C', purchase_amount: '990', cost_session: '35' },
      ],
    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    openWushare(path) {
      let url = path;
      url = 'https://google.com';
      window.open(url, '_blank');
    },
  },
};
</script>
