const LearnRuleModalLang = {
  messages: {
    tw: {
      'learn_rule': '學習條款',
      'notice_des': '※注意！您目前選擇「加價購之優惠」，即將扣除<span style="text-decoration-line: underline;">&nbsp;{0}堂&nbsp;</span>堂數。扣除後，得以學員專屬優惠價加價購買WuShare產品。',
      'notice': '注意事項',
      'learn_rule_rule_1': '1.WUWOW學員使用堂數加價購方案，不適用<a href="https://wushare.tw/course-paying-user-agreement/" target="_blank" style="text-decoration: underline; font-weight: 700;">WuShare 網際網路課程購買暨服務契約</a>第16條終止契約與退費。購買堂數加價購方案，恕不提供退費/退貨服務。',
      'learn_rule_rule_2': '2.WuShare產品所提供之一對一課程，需按WuShare預約規範。不得指定顧問授課，並由WuShare提供之課程相關教材。',
      'have_read_rule': '我已閱讀<a href="https://wushare.tw/course-paying-user-agreement/" target="_blank" style="text-decoration: underline; font-weight: 700;">WuShare 網際網路課程購買暨服務契約</a>及注意事項',
    },
    en: {
      'learn_rule': 'Learn Rule',
      'notice_des': '{cost_session}',
      'notice': 'Notice',
      'learn_rule_rule_1': '',
      'learn_rule_rule_2': '',
      'have_read_rule': '',
    },
  },
};

export {
  LearnRuleModalLang
};
