<template lang="pug">
  #advertisementTitle.wuwow-card.phone-width.self-content
    .wuwow-card-head.text-center
      h4.wuwow-card-title WuShare首堂課程(B)
      h4.wuwow-card-title 8小時英文邏輯速成班(B)
    .wuwow-card-body.text-center.card-body
      .iframe-box.mt-4
        iframe(width='100%' height='100%' src='https://www.youtube.com/embed/h6tcSHEd9fA' frameborder='0' allowfullscreen='')
      hr
      .productInfo
        b-button(variant='primary' @click='switchProductInfo()')
          span(v-if='!showProductInfo') {{$t('open_product_info')}}
          span(v-else-if='showProductInfo') {{$t('hide_product_info')}}
        br
        br
        transition(name='fade')
          div(v-if='showProductInfo') 8小時邏輯速成班HTML內容(B)
        b-button(variant='primary' v-if='showProductInfo' @click='switchProductInfo()')
          a(href='#adTitle') {{$t('hide_product_info')}}
      #selfPurchaseTitlePosition
</template>

<script type="text/javascript">
import { productInfoLang } from './lang';
export default {
  name: 'ProductInfo',

  components: {},

  i18n: productInfoLang,

  data() {
    return {
      showProductInfo: false,
    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    switchProductInfo() {
      this.showProductInfo = !this.showProductInfo;
      this.gotoTop();
    },

    gotoTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss"></style>
