var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "recommend_info_modal",
      "centered": "",
      "title": _vm.$t('notice'),
      "header-bg-variant": "primary",
      "header-text-variant": "light",
      "hide-footer": ""
    }
  }, [_c('ol', {
    staticClass: "my-4"
  }, _vm._l(_vm.rules, function (rule, index) {
    return _c('li', {
      key: index
    }, [_vm._v(_vm._s(rule))]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }