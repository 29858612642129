const productInfoLang = {
  messages: {
    tw: {
      'open_product_info': '顯示更多資訊',
      'hide_product_info': '隱藏詳細內容',
    },
    en: {
      'open_product_info': 'Show Info',
      'hide_product_info': 'Hide Info',
    },
  },
};

export {
  productInfoLang
};
