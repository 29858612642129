<template lang="pug">
  b-modal#recommend_info_modal(centered :title="$t('notice')" header-bg-variant="primary" header-text-variant="light" hide-footer)
    ol.my-4
      li(v-for='(rule,index) in rules' :key="index") {{rule}}
</template>

<script type="text/javascript">
import { recommendInfoModalLang } from './lang';
export default {
  props: {
  },

  i18n: recommendInfoModalLang,

  data() {
    return {
      rules: [
        this.$t('rule_1'),
        this.$t('rule_2'),
        this.$t('rule_3'),
        this.$t('rule_4'),
      ],
    };
  },
};
</script>
