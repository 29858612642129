<template lang="pug">
  #recommendTitlePosition.wuwow-card.phone-width.recommend-content
    .wuwow-card-head.text-center
      h4.wuwow-card-title 「8小時英文邏輯速成班」推薦好友獎勵活動！(B)
       font-awesome-icon.icon-infor(icon="info-circle" size="lg" v-b-modal.recommend_info_modal)
    .wuwow-card-body.text-center.card-body
      table.table.table-hover.table-clsapc-record
        thead.thead-default
          tr
            th {{$t('pruduct_name')}}
            th {{$t('bonus_note')}}
            th {{$t('discount_amount')}}
            th
        tbody
          tr(v-for='item in FAKE_recommend_act' :key='item.description')
            td {{ item.description }}
            td(style='width: 10px;')
              | {{item.bonus_note}}
            td
              | {{item.discount_amount}}
              template(v-if="item.discount_type == 'percent'")
                span %
            td
              b-button.btn-recommend-code(variant='outline-primary' v-b-modal.code_modal) {{$t('show_recommend_code')}}
      div
        span.text-danger.warning-text  {{$t('recommend_warning')}}
    recommend-info-modal
</template>

<script type="text/javascript">
import { productInfoLang } from './lang';
import RecommendInfoModal from './recommendInfoModal';

export default {
  name: 'RecommendActivity',

  components: {
    RecommendInfoModal,
  },

  i18n: productInfoLang,

  data() {
    return {
      FAKE_recommend_act: [
        {
          description: '方案A',
          bonus_note: '現金150元',
          discount_amount: '150 元',
          discount_type: '',
        },
        {
          description: '方案B',
          bonus_note: 'WUWOW堂數*1',
          discount_amount: '150 元',
          discount_type: '',
        },
        {
          description: '方案C',
          bonus_note: 'x',
          discount_amount: '300 元',
          discount_type: 'percent',
        },
      ],
    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    showCodeModal() {},
  },
};
</script>
