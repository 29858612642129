const selfPurchaseDiscountLang = {
  messages: {
    tw: {
      'code': '優惠碼',
      'copy': '複製',
      'self_purchase_warning': '※注意: 此優惠碼僅能使用',
      'pruduct_link': '學員專屬購買連結',
    },
    en: {
      'code': 'Code',
      'copy': 'Copy',
      'self_purchase_warning': '※Notice: Code can be used only',
      'pruduct_link': 'Purchase Link',
    },
  },
};

export {
  selfPurchaseDiscountLang
};
