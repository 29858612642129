const leftMenuListLang = {
  messages: {
    tw: {
      'menu_list': '內容目錄',
    },
    en: {
      'menu_list': 'Menu',
    },
  },
};

export {
  leftMenuListLang
};
