const recommendInfoModalLang = {
  messages: {
    tw: {
      'notice': '注意事項',
      'rule_1': '獎勵可進行累積，並且不限數量。',
      'rule_2': '推薦之購買者於若申請退款，則不予計算獎勵。',
      'rule_3': '課程開課後，每月於推薦數量統計審核後，統一發放推薦獎勵。',
      'rule_4': '獎勵品項將統一以使用之優惠碼統計，恕不提供品項更動。',
    },
    en: {
      'notice': 'Notice',
      'rule_1': '獎勵可進行累積，並且不限數量。',
      'rule_2': '推薦之購買者於若申請退款，則不予計算獎勵。',
      'rule_3': '課程開課後，每月於推薦數量統計審核後，統一發放推薦獎勵。',
      'rule_4': '獎勵品項將統一以使用之優惠碼統計，恕不提供品項更動。',
    },
  },
};

export {
  recommendInfoModalLang
};
