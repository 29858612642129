var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('left-menu-list'), _c('product-info'), _c('self-purchase-discount'), _c('deduct-class-purchase-discount'), _c('recommend-activity'), _c('code-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }