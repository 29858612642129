<template lang="pug">
  .menu-list.wuwow-card
    .wuwow-card-head.text-center(@click='switchMenuList()')
      | {{ $t('menu_list') }}
    transition(name='fade')
      b-list-group(v-if='!showMenuList')
        b-list-group-item(href='#' @click='gotoTop()') WuShare首堂課程(B)
        b-list-group-item(href='#selfPurchaseTitlePosition') 學員專屬自購優惠(B)
        b-list-group-item(href='#additionalPrchaseTitlePosition') 學員專屬堂數加價購(B)
        b-list-group-item(href='#recommendTitlePosition') 8小時邏輯速成班推薦好友獎勵活動!(B)
</template>

<script type="text/javascript">
import { leftMenuListLang } from './lang';
export default {
  name: 'LeftMenuList',

  components: {},

  i18n: leftMenuListLang,

  data() {
    return {
      showMenuList: false,
    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    switchMenuList() {
      this.showMenuList = !this.showMenuList;
    },

    gotoTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
