<template lang="pug">
  b-modal#code_modal(centered :title="$t('my_code')" header-bg-variant="primary" header-text-variant="light" hide-footer)
    .text-center
      span(style='font-size: 28px;') &nbsp; 10QO3IGF-V-27205-A(B) &nbsp;
      br
      b-button.copy-btn.my-4(variant='primary' v-clipboard:copy='"10QO3IGF-V-27205-A(B)"' v-clipboard:success='onCopy') {{$t('copy')}}
</template>

<script type="text/javascript">
import { codeModalLang } from './lang';
export default {
  name: 'CodeModal',

  props: {},

  i18n: codeModalLang,

  data() {
    return {};
  },

  methods: {
    onCopy() {
      console.log(1);
    },
  },
};
</script>
