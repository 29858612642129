const codeModalLang = {
  messages: {
    tw: {
      'my_code': '我的推薦碼',
      'copy': '複製',
    },
    en: {
      'my_code': 'My Code',
      'copy': 'Copy',
    },
  },
};

export {
  codeModalLang
};
