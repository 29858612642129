<template lang="pug">
  .wuwow-card.phone-width.self-content
    .wuwow-card-head.text-center
      h4.wuwow-card-title WUWOW學員專屬自購優惠(B)
    .wuwow-card-body.text-center.card-body
      img(src='http://cdn.wushare.tw/images/event/8hrs-english-logic-bootcamp-for-wuwow-vip.png' style='width: 100%;')
      .self-coupon
        span.mr-2 {{$t('code')}}:
        span.coupon-code-text.px-2.py-1 10QO5BR0-J-27205-S(B)
        b-button(variant='outline-primary btn-sm ml-2' v-clipboard:copy='"10QO5BR0-J-27205-S(B)"' v-clipboard:success='onCopySelf') {{$t('copy')}}
      div
        span.text-danger.warning-text  {{$t('self_purchase_warning')}} 1
      b-button#additionalPrchaseTitlePosition.mt-3(variant='primary' @click='openWushare()') {{$t('pruduct_link')}}
</template>

<script type="text/javascript">
import { selfPurchaseDiscountLang } from './lang';
export default {
  name: 'SelfPurchaseDiscount',

  components: {},

  i18n: selfPurchaseDiscountLang,

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    openWushare(path) {
      let url = path;
      url = 'https://google.com';
      window.open(url, '_blank');
    },

    onCopySelf() {
      console.log('copied');
    },
  },
};
</script>
