var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card phone-width self-content",
    attrs: {
      "id": "advertisementTitle"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "wuwow-card-body text-center card-body"
  }, [_vm._m(1), _c('hr'), _c('div', {
    staticClass: "productInfo"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.switchProductInfo();
      }
    }
  }, [!_vm.showProductInfo ? _c('span', [_vm._v(_vm._s(_vm.$t('open_product_info')))]) : _vm.showProductInfo ? _c('span', [_vm._v(_vm._s(_vm.$t('hide_product_info')))]) : _vm._e()]), _c('br'), _c('br'), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.showProductInfo ? _c('div', [_vm._v("8小時邏輯速成班HTML內容(B)")]) : _vm._e()]), _vm.showProductInfo ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.switchProductInfo();
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#adTitle"
    }
  }, [_vm._v(_vm._s(_vm.$t('hide_product_info')))])]) : _vm._e()], 1), _c('div', {
    attrs: {
      "id": "selfPurchaseTitlePosition"
    }
  })])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card-head text-center"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v("WuShare首堂課程(B)")]), _c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v("8小時英文邏輯速成班(B)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iframe-box mt-4"
  }, [_c('iframe', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "src": "https://www.youtube.com/embed/h6tcSHEd9fA",
      "frameborder": "0",
      "allowfullscreen": ""
    }
  })]);

}]

export { render, staticRenderFns }