const deductClassPurchaseDiscountLang = {
  messages: {
    tw: {
      'additional_purchase': '加價購方案',
      'additional_purchase_price': '現金',
      'deduct_class': '使用堂數',
      'exchange': '兌換優惠碼',
      'show_code': '取得優惠碼',
      'additional_purchase_warning': '※加價購方案僅能選擇其一且僅能使用一次，選擇後將無法更改其他方案！',
      'pruduct_link': '學員專屬購買連結',
    },
    en: {
      'additional_purchase': 'Product',
      'additional_purchase_price': 'Price',
      'deduct_class': 'Deduct Class',
      'exchange': 'Exchange Code',
      'show_code': 'Get Code',
      'additional_purchase_warning': '※',
      'pruduct_link': 'Purchase Link',
    },
  },
};

export {
  deductClassPurchaseDiscountLang
};
