<template lang="pug">
  b-modal#learn_rule_modal(centered :title="$t('learn_rule')" header-bg-variant="primary" header-text-variant="light" hide-footer)
      h5.text-danger
        span(v-html="$t('notice_des', [5])")
      br
      h5(style='font-weight: 700;') {{ $t('notice') }}
      p
        span(v-html="$t('learn_rule_rule_1')")
        br
        span(v-html="$t('learn_rule_rule_2')")
      b-form-checkbox#checkbox(name='checkbox' value='checked' unchecked-value='unchecked')
        span(v-html="$t('have_read_rule')")

</template>

<script type="text/javascript">
import { LearnRuleModalLang } from './lang';
export default {
  name: 'LearnRuleModal',

  props: {},

  i18n: LearnRuleModalLang,

  data() {
    return {};
  },

  methods: {

  },
};
</script>
