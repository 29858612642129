<template lang="pug">
  div
    left-menu-list
    product-info
    self-purchase-discount
    deduct-class-purchase-discount
    recommend-activity
    code-modal
</template>

<script type="text/javascript">
import LeftMenuList from '@/components/wushare/leftMenuList';
import ProductInfo from '@/components/wushare/productInfo';
import SelfPurchaseDiscount from '@/components/wushare/selfPurchaseDiscount';
import DeductClassPurchaseDiscount from '@/components/wushare/deductClassPurchaseDiscount';
import RecommendActivity from '@/components/wushare/recommendActivity';
import CodeModal from '@/components/wushare/codeModal';
export default {
  name: 'Coupon',

  components: {
    ProductInfo,
    LeftMenuList,
    SelfPurchaseDiscount,
    DeductClassPurchaseDiscount,
    RecommendActivity,
    CodeModal,
  },

  data() {
    return {

    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {

  },
};
</script>

<style lang="scss">
@import './couponList';
@import '@/styles/transition';
</style>
