const productInfoLang = {
  messages: {
    tw: {
      'pruduct_name': '推薦方案',
      'bonus_note	': '學員獎勵	',
      'discount_amount': '好友折扣',
      'show_recommend_code': '取得推薦碼',
      'recommend_warning': '推薦好友使用你的推薦碼購買，讓好友享折扣，你拿獎勵！',
    },
    en: {
      'pruduct_name': 'Pruduct',
      'bonus_note	': 'Bonus',
      'discount_amount': 'Discount',
      'show_recommend_code': 'Recommend Code',
      'recommend_warning': 'Recommend Your Friends To Buy.',
    },
  },
};

export {
  productInfoLang
};
